<template>
  <div class="index">

    <el-row style="border-bottom:'1px solid #EEE';  background: #990005; color: #fff;">
      <el-col :span="6">
        <div class="logo flex YCenter">

          <img src="../assets/images/niuniulogo.png" alt="" srcset="">
          <div>
            <p class="companyName">牛牛收卡</p>
            <p class="remark">专业卡券服务平台</p>
          </div>

        </div>
      </el-col>
      <el-col :span="13">
        <div>
          <el-menu :default-active="this.$route.path" router mode="horizontal" active-text-color="#fff"
            text-color="#f0f0f0" background-color="#990005">
            <el-menu-item v-for="(item, i) in navList" :key="i" :index="item.name">
              {{ item.navItem }}
            </el-menu-item>
          </el-menu>
        </div>
      </el-col>
      <el-col :span="3">
        <div class='loginButton XYCenter' v-if="!$store.state.isLogin" @click="jumpMemberCenter">
          <span> <i class="el-icon-user"></i> 登录/注册</span>
          <!-- <span v-if="!$store.state.isLogin"> <i class="el-icon-user"></i> 退出登录</span> -->

        </div>
        <div class="loginOut" v-if="$store.state.isLogin"><el-dropdown :hide-on-click="false" @command="loginOut">
            <span class="el-dropdown-link">
              <i class="el-icon-s-custom"></i><i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown" split-button>
              <el-dropdown-item>退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown></div>
      </el-col>
    </el-row>
    <router-view></router-view>
    <footer class="footer ">
      <div class="flex border-b-1px">
        <div class="footerTag XYCenter w60">
          <div class="flexColumn buttonList">
            <el-button type="text" class="tag" v-for="(item, index) in footerButtonRouterList1" :key="index"
              @click="jump(item)">{{
                item.title }}</el-button>

          </div>
          <div class="flexColumn buttonList">
            <el-button type="text" class="tag" v-for="(item, index) in footerButtonRouterList2" :key="index"
              @click="jump(item)">{{
                item.title }}</el-button>
            <el-button type="text" class="tag" @click="jumpMemberCenter">立即登录</el-button>


          </div>
        </div>
        <div class="border-r-1px"></div>
        <div class="footerTag XYCenter">
          <div class="flexColumn buttonList">
            <el-button type="text" class="tag">客服电话：19146487980</el-button>
            <span
              style="width: 500px;display: inline-block;margin-left: 22px;">办公地址：深圳市福田区南园街道园西社区华发南路64号爱住大院14栋102</span>

          </div>

        </div>
      </div>
      <div class="XYCenter recordNumber">
        <a href="https://beian.miit.gov.cn">粤ICP备17118875号-2</a>
      </div>
    </footer>
    <el-backtop :bottom="200">
      <div style="{
      height: 100%;
      width: 100%;
      background-color: #f2f5f6;
      box-shadow: 0 0 6px rgba(0,0,0, .12);
      border-radius:50%;
      text-align: center;
      line-height: 40px;
      color: #1989fa;
      font-size:12px
                  }">
        回顶
      </div>
    </el-backtop>

  </div>
</template>

<script>

export default {
  data() {
    return {
      reqCardTypeParams: {
        gc_type: 3,
        page: 1,
        limit: 100,
      },
      navList: [
        { name: "/home", navItem: "首页" },
        { name: "/cardRecycle", navItem: "卡券回收" },
        { name: "/contactUs", navItem: "联系我们" },
        { name: "/memberCenter", navItem: "会员中心" },
      ],
      footerButtonRouterList1: [
        { title: '卡券回收', path: '/cardRecycle' },
        { title: '平台公告', path: '/announcement' },
        { title: '转让协议', path: '/agreement' },

      ],
      footerButtonRouterList2: [
        { title: '常见问题', path: '/memberCenter/commonProblem' },
        { title: '联系我们', path: '/contactUs' },

      ],
      cardTypeList: []

    };
  },

  components: {},

  mounted() {

  },

  methods: {
    jump(item) {
      this.$router.push({
        path: item.path
      })
    },
    jumpMemberCenter() {
      this.$router.push({
        path: "/memberCenter"
      })
    },
    async getCardTypeList() {
      let res = await this.$axios
        .get("/api/shop/getGoodsClassList", {
          params: this.reqCardTypeParams,
        })
      this.cardTypeList = res.data.rows
    },
    async loginOut() {
      let res = await this.$axios
        .get("/api/user/logout")

      if (res.code == '000') {
        this.$store.dispatch('setUserToken', '')
        this.$store.dispatch('setIsLogin', false)
        this.$router.push({ path: '/memberCenter' })
      }
    }
  },
};
</script>
<style lang='scss' scoped>
.index {
  background-color: $bgColor;
}

.el-menu--horizontal>.el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus {
  background-color: $bgColor;
}

.el-row {
  border-bottom: solid 1px #e6e6e6;
}

.el-menu-item {
  font-size: 18px;
  padding: 0 40px;
}

.el-menu.el-menu--horizontal {
  border: none;
}

.el-menu {
  background-color: transparent;
}

.logo {
  height: 60px;
  margin-left: 50px;

  img {
    width: 55px;
    margin-right: 7px;
  }

  .companyName {
    font-size: 15px;
    font-weight: 600;

  }

  .remark {
    margin-top: 5px;

  }

}

.loginButton {
  height: 60px;
  cursor: pointer;
  font-size: 15px;

  span {

    display: inline-block;
    height: 30px;
    width: 100px;
    text-align: center;
    line-height: 30px;
    border-radius: 15px;
    color: #fff;
    // background: $themeColor;
  }
}

.loginOut {
  height: 60px;
  line-height: 60px;
  text-align: right;
  cursor: pointer;
  font-size: 20px;

  .el-dropdown-link {
    font-size: 20px;
    color: #fff;
  }
}

.footer {
  margin-bottom: 2px;
  height: 170px;
  background: #990005;
  color: #fff;

  .footerTag {
    height: 130px;
    font-size: 15px;


    .buttonList {
      width: 200px;
    }

    .tag {
      color: #fff;
      margin-left: 0px
    }

  }

  .w60 {
    width: 60%;
  }

  .recordNumber {
    height: 40px;
    color: #fff;
  }
}
</style>